<template>
  <iq-card>
    <template v:slot="body">
      <div class="common-part">
        <h3>Donor Information</h3>
        <form class="needs-validation" novalidate action="#">
          <div class="form-row">
            <div class="col-md-6 mb-3">
              <label for="validationCustom01" class="appendAsterisk">First name</label>
              <input type="text" class="form-control" disabled @change="getForm" v-model="form.first_name"
                id="validationCustom01" required />
              <div class="valid-feedback">Looks good!</div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="validationCustom02" class="appendAsterisk">Last name</label>
              <input type="text" class="form-control" disabled @change="getForm" v-model="form.last_name" id="validationCustom02"
                required />
              <div class="valid-feedback">Looks good!</div>
            </div>
            <!-- <div class="pl-2 pt-2 pr-2 pb-0">Organization</div>
            <div class="w-100 p-2">
              <el-select
                class="w-100"
                v-model="form.organization"
                filterable
                remote
                reserve-keyword
                :remote-method="organizationSearch"
                @change="getForm"
                placeholder="Enter keyword search organization"
              >
                <el-option
                  v-for="item in orgList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div> -->
            <div class="col-md-12 mb-3">
              <label for="validationCustomUsername" class="appendAsterisk">Email</label>
              <input type="text" @change="getForm" disabled class="form-control" v-model="form.email"
                id="validationCustomUsername" aria-describedby="inputGroupPrepend" required />
            </div>
            <div class="col-md-12 mb-3">
              <!-- <CountrySelection @returnPrams="getAddressParams" :grandsonClassName="['col-md-6']" CountryLabel="Country"
                RegionLabel="Region" /> -->
              <label for="validationCustomUsername" class="appendAsterisk">Country</label>
              <select class="form-control" v-model="form.country_id" @change="getForm">
                <option v-for="v in countryList" :key="v.id" :value="v.id">
                  {{ v | addressName }}
                </option>
              </select>
            </div>
            <div class="col-md-12 mb-3">
              <label for="validationCustom01" class="appendAsterisk"
                >Address</label
              >
              <input
                type="text"
                @change="getForm"
                class="form-control"
                v-model="form.address"
                id="validationCustom01"
                required
              />
            </div>
            <div class="col-md-12 mb-3">
              <label for="validationCustom01" class="appendAsterisk"
                >Telephone</label
              >
              <input
                type="text"
                @change="getForm"
                class="form-control"
                v-model="form.tel"
                id="validationCustom01"
                required
              />
            </div>
            <div class="col-md-12 mb-3">
              <label for="validationCustom01" class="appendAsterisk"
                >Fax</label
              >
              <input
                type="text"
                @change="getForm"
                class="form-control"
                v-model="form.fax"
                id="validationCustom01"
                required
              />
            </div>
            <div class="col-md-12 mb-3">
              <label for="validationCustom01" class="appendAsterisk"
                >Company (if it is a corporate donor) </label
              >
              <input
                type="text"
                @change="getForm"
                class="form-control"
                v-model="form.company"
                id="validationCustom01"
                required
              />
            </div>
          </div>
        </form>
      </div>
    </template>
  </iq-card>
</template>

<script>
  // import CountrySelection from "@/components/ComComponents/CountrySelection";
  export default {
    // components: { CountrySelection },

    data() {
      return {
        orgList: [],
        countryList: [],
        form: {
          first_name: "",
          last_name: "",
          country_id: "",
          email: "",
          address: "",
          tel: "",
          fax: "",
          company: "",
        },
      };
    },

    filters: {
      // 筛选地区名字
      addressName(val) {
        return val['name']
      }
    },

    created() {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      let userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));
      this.form.first_name = userInfo.first_name
      this.form.last_name = userInfo.last_name
      this.form.email = userLoginInfo.email
      this.getAddress()
    },

    methods: {
      getForm() {
        this.$emit("getInform", this.form);
      },

      async getAddress() {
        let { status, data } = await this.$http.getAddress()
        if (status == 200) {
          this.countryList = data
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .common-part {
    padding: 20px;
    color: #333;

    >h3 {
      font-size: 16px;
      color: #333;
      font-weight: 500;
      margin-bottom: 26px;
    }
  }

  ::v-deep .el-date-editor--date .el-input__inner,
  ::v-deep .el-select .el-input__inner {
    height: 45px;
    border-radius: 10px;
  }
</style>